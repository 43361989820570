import React, { FC } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'gatsby';

import Image from 'common/Image';

import { BannerProps } from './models';

const Banner: FC<BannerProps> = ({ bannerSlider }) => (
  <Carousel infiniteLoop showArrows showThumbs={false} showStatus={false}>
    {bannerSlider.map(({ bannerDesktop, bannerMobile, bannerText1, bannerText2, link }) => (
      <div key={bannerDesktop.id}>
        <Link to={link.url ? link.url : ''}>
          <Image imageData={bannerDesktop} alt={bannerDesktop.name} className="d-none d-md-block" />
          <Image imageData={bannerMobile} alt={bannerMobile.name} className="d-md-none" />
          <h2 className="title--left">{bannerText1}</h2>
          <h2 className="title--right">{bannerText2}</h2>
        </Link>
      </div>
    ))}
  </Carousel>
);

export default Banner;
